<template>
  <b-card>
      <!-- form -->
      <b-button
        v-ripple.400="'rgba(234, 84, 85, 0.15)'"
        variant="outline-primary"
        class="mt-0 mt-md-2"
        @click="openUpload()"
        :disabled="$store.getters.getLoader"
      >
        <b-spinner
          v-if="$store.getters.getLoader"
          class="mr-1"
          small
          variant="light"
        />
        <feather-icon icon="PaperclipIcon" class="mr-25" />
        Upload File
      </b-button>
      <b-form class="mt-2">
        <b-row>
          <b-col cols="12" md="12" lg="12">
            <b-table
              :fields="fields"
              :items="files"
            >
              <template #cell(file)="data">
                <b-button
                  variant="primary"
                  class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                  size="sm"
                  @click="downloadFile(data.item)"
                  :disabled="$store.getters.getLoader">
                  <feather-icon
                    icon="SaveIcon"
                    class="mr-50"
                    size="16"
                    v-if="!$store.getters.getLoader"
                  />
                  <b-spinner
                    v-if="$store.getters.getLoader"
                    class="mr-1"
                    small
                    variant="light"
                  />
                  {{ $t('Download') }}
                </b-button>
                <b-button
                  variant="danger"
                  class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                  size="sm"
                  @click="removeFile(data.item)"
                  :disabled="$store.getters.getLoader">
                  <feather-icon
                    icon="DeleteIcon"
                    class="mr-50"
                    size="16"
                    v-if="!$store.getters.getLoader"
                  />
                  <b-spinner
                    v-if="$store.getters.getLoader"
                    class="mr-1"
                    small
                    variant="light"
                  />
                  {{ $t('Remove') }}
                </b-button>
              </template>
            </b-table>
          </b-col>
        </b-row>
      </b-form>
  </b-card>
</template>

<script>
// Alert
import Ripple from 'vue-ripple-directive'

import ApiService from '@/common/api.service'
// Alert Toast
import AlertService from '@/common/alert.service'
import ToastificationSuccess from '@core/components/toastification/ToastificationSuccess.vue'

export default {
  directives: {
    Ripple,
  },
  data() {
    return {
      fields: [
        {
          key: 'fileName',
          label: 'fileName',
        },
        {
          // A regular column with custom formatter
          key: 'fileFormat',
          label: 'fileFormat',
        },
        {
          // A virtual column with custom formatter
          key: 'file',
          label: 'file',
          class: 'text-right',
        },
      ],
    }
  },
  methods: {
    openUpload() {
      const paramId = this.$router.currentRoute.params.id;
      if (paramId === 0) {
        AlertService.error(this, 'Please first save and then try to upload picture.');
        return;
      }

      const fileInput = document.createElement('input');
      fileInput.type = 'file';
      fileInput.addEventListener('change', event => {
        const fileForm = new FormData();
        fileForm.set('file', event.target.files[0]);
        fileForm.set('orderId', paramId);

        ApiService.upload('OrderFile/upload', fileForm, this)
          .then(response => {
            if (response.statusCode === 200) {
              this.$toast({
                component: ToastificationSuccess,
                props: {
                  title: response.message,
                  icon: 'CheckCircleIcon',
                  variant: 'info',
                },
              });
              this.$store.dispatch('orderManagement/fetchOrder', { id: paramId });
            } else {
              AlertService.error(this, response.message)
            }
          });
      })
      fileInput.click();
    },

    downloadFile(item) {
      this.$store.dispatch('orderManagement/downloadUploadOrderFile', item.id)
        .then(response => {
          const fileURL = window.URL.createObjectURL(new Blob([response], { type: item.fileFormat }));
          const fileLink = document.createElement('a');
          fileLink.href = fileURL;
          fileLink.setAttribute('download', item.fileName);
          document.body.appendChild(fileLink);

          fileLink.click();
        })
    },

    removeFile(item) {
      AlertService.confirmDelete(this, () => {
        this.$store.dispatch('orderManagement/removeOrderFile', item.id)
          .then(response => {
            if (response.statusCode === 200) {
              this.$toast({
                component: ToastificationSuccess,
                props: {
                  title: response.message,
                  icon: 'CheckCircleIcon',
                  variant: 'info',
                },
              });

              const paramId = this.$router.currentRoute.params.id;
              this.$store.dispatch('orderManagement/fetchOrder', { id: paramId });
            } else {
              AlertService.error(this, response.message)
            }
          })
      });
    },
  },
  computed: {
    files() {
      return this.$store.getters['orderManagement/getOrderUploadedFiles']
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
