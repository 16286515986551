<template>
  <b-card>
      <!-- form -->
      <b-form class="mt-2">
        <b-row>
          <b-col cols="12" md="8" lg="8">
            <b-row>
              <b-col cols="12" md="6" lg="6">
                <b-form-group :label="$t('Order No')" label-for="orderNo">
                  <b-form-input id="orderNo" v-model="orderNo" readonly/>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12" md="6" lg="6">
                <b-form-group :label="$t('Order Title')" label-for="orderTitle">
                  <b-form-input id="orderTitle" v-model="orderTitle" readonly/>
                </b-form-group>
              </b-col>
              <b-col cols="12" md="6" lg="6">
                <b-form-group
                  label=""
                  label-for="contact-options"
                  label-class="mb-1">
                  <div class="demo-inline-spacing">
                    <b-form-checkbox
                      v-model="isActive"
                      class="custom-control-primary">
                      {{ $t('Active')}}
                    </b-form-checkbox>
                  </div>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12" md="6" lg="6">
                <b-form-group :label="$t('User Name')" label-for="user.fullName">
                  <b-form-input id="user.fullName" v-model="user.fullName" readonly/>
                </b-form-group>
              </b-col>
              <b-col cols="12" md="6" lg="6">
                <b-form-group :label="$t('User Name')" label-for="user.userName">
                  <b-form-input id="user.userName" v-model="user.userName" readonly/>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12" md="6" lg="6">
                <b-form-group :label="$t('Sub Total')" label-for="subtotal">
                  <b-form-input id="subtotal" v-model="subtotal" readonly/>
                </b-form-group>
              </b-col>
              <b-col cols="12" md="6" lg="6">
                <b-form-group :label="$t('Currency Unit')" label-for="currencyUnit">
                  <b-form-input id="currencyUnit" v-model="currencyUnit" readonly/>
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>
          <b-col cols="12" md="4" lg="4">
            <b-row>
              <b-col cols="12" md="12" lg="12">
                <b-form-group :label="$t('Order Status')" label-for="orderStatus">
                  <v-select
                    v-model="orderStatus"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="orderStatusOptions"
                    :reduce="(val) => val.value"
                    :clearable="false"
                    input-id="orderStatus"
                    placeholder="Select Status"/>
                </b-form-group>
              </b-col>
              <b-col cols="12" md="12" lg="12">
                <b-form-group :label="$t('Payment Type')" label-for="paymentData_paymentType">
                  <v-select
                    v-model="paymentData.paymentType"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="paymentTypeOptions"
                    :reduce="(val) => val.value"
                    :clearable="false"
                    input-id="paymentData_paymentType"
                    placeholder="Select Payment Type" :disabled="true"/>
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="12" md="4" lg="4">
            <b-form-group :label="$t('Payment No')" label-for="paymentData_paymentNo">
              <b-form-input id="paymentData_paymentNo" v-model="paymentData.paymentNo" readonly/>
            </b-form-group>
          </b-col>
          <b-col cols="12" md="4" lg="4">
            <b-form-group :label="$t('Payment Amount')" label-for="paymentData_paymentAmount">
              <b-form-input id="paymentData_paymentAmount" v-model="paymentData.paymentAmount"/>
            </b-form-group>
          </b-col>
          <b-col cols="12" md="4" lg="4">
            <b-form-group
              label=""
              label-class="mb-1">
              <div class="demo-inline-spacing">
                <b-form-checkbox
                  v-model="paymentData.isComplete"
                  class="custom-control-primary">
                  {{ $t('Payment Success')}}
                </b-form-checkbox>
              </div>
            </b-form-group>
          </b-col>
          <b-col cols="12" md="12" lg="12">
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="12">
            <save-button 
              :onClickSave="saveItem"
              :showSaveAndClose="false"
              />
          </b-col>
        </b-row>

      </b-form>
  </b-card>
</template>

<script>
// Toast
import ToastificationSuccess from '@core/components/toastification/ToastificationSuccess.vue'
// Alert
import AlertService from '@/common/alert.service'
import { createHelpers } from 'vuex-map-fields';
import Ripple from 'vue-ripple-directive'
import SaveButton from '@/components/SaveButton.vue';

// Options
import StaticOptions from '@/common/options/StaticOptions';

const { mapFields } = createHelpers({
  getterType: 'orderManagement/getOrderField',
  mutationType: 'orderManagement/updateOrderField',
});

export default {
  components: {
    SaveButton,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      orderStatusOptions: StaticOptions.orderStatusOptions,
      paymentTypeOptions: StaticOptions.paymentTypeOptions,
      paymentData: {},
    }
  },
  methods: {
    fetchOrderPayment() {
      this.$store.dispatch('paymentManagement/fetchPayment', { id: this.paymentId })
        .then(response => {
          if (response.statusCode === 200) {
            this.paymentData = response.result;
          } else {
            AlertService.error(this, response.message)
          }
        });
    },
    saveItem() {
      this.$store.dispatch('orderManagement/saveOrder')
        .then(response => {
          if (response.statusCode === 200) {
            this.$toast({
              component: ToastificationSuccess,
              position: 'top-right',
              props: { text: response.message },
            });

            this.savePayment();
          } else {
            AlertService.error(this, response.message)
          }
        });
    },

    savePayment() {
      if (this.orderStatus === 3) {
        this.paymentData.isComplete = true;
      }
      
      this.$store.dispatch('paymentManagement/savePayment', this.paymentData)
        .then(response => {
          if (response.statusCode === 200) {
            this.$toast({
              component: ToastificationSuccess,
              position: 'top-right',
              props: { text: response.message },
            })
            if (this.paymentData.isComplete && this.orderStatus === 3) {
              AlertService.confirmSuccess(this, () => {
                this.sendRawDataWaitingMixedModeEmail()
              }, 'Do you want to send raw data Processed and waiting mixed mode email')
            }
          } else {
            AlertService.error(this, response.message)
          }
        });
    },

    sendRawDataWaitingMixedModeEmail() {
      this.$store.dispatch('orderManagement/sendRawDataWaitingMixedModeEmail')
        .then(response => {
          if (response.statusCode === 200) {
            this.$toast({
              component: ToastificationSuccess,
              position: 'top-right',
              props: { text: response.message },
            })
          } else {
            AlertService.error(this, response.message)
          }
        });
    },
  },
  computed: {
    ...mapFields([
      'id',
      'isActive',
      'orderNo',
      'subtotal',
      'currencyUnit',
      'orderTitle',
      'user',
      'paymentId',
      'presetRegion',
      'paymentTypeLabel',
      'orderStatus',
      'orderDate',
    ]),
  },
  mounted() {
    this.fetchOrderPayment();
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
