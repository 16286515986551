<template>
  <div>
    <b-modal
      ref="modal-save"
      title="Order Result"
      ok-title="Save"
      cancel-title="Close"
      cancel-variant="outline-secondary"
      no-close-on-backdrop
      centered
      @hidden="hiddenModal"
      @ok="handleOk"
      size="lg"
    >
      <validation-observer ref="saveOrderResult">
        <b-form
          ref="form"
          @submit.stop.prevent="saveOrderResult">
          <b-form-group
            :label="$t('Result Title')"
          >
           <validation-provider #default="{ errors }" rules="required" name="Ad">
            <b-form-input
              id="resultTitle"
              v-model="modalObject.item.resultTitle"
              :placeholder="$t('Ad')"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
          </b-form-group>
          <b-form-group
            :label="$t('Result Status')"
          >
            <v-select
              v-model="resultStatus"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="orderResultTraitStatusOptions"
              :reduce="(val) => val.value"
              :clearable="false"
              input-id="orderStatus"
              placeholder="Select Status"/>
          </b-form-group>

          <b-form-group
            label="Result Desc"
          >
           <validation-provider #default="{ errors }" name="Result Desc">
            <b-form-input
              v-model="modalObject.item.resultDescription"
              type="text"
              placeholder="Result Desc"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
          </b-form-group>

          <b-form-group :label="$t('Select Raw Data File')" label-for="orderUploadedFileId">
            <validation-provider #default="{ errors }" rules="required" name="Type">
              <v-select
                v-model="modalObject.item.orderUploadedFileId"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="files"
                :reduce="(val) => val.id"
                :clearable="false"
                label="fileName"
                placeholder="Select Raw Data File"
                :state="errors.length > 0 ? false:null"
                input-id="orderUploadedFileId"/>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

        </b-form>
      </validation-observer>
    </b-modal>
  </div>
</template>

<script>
import ApiService from '@/common/api.service'
import AlertService from '@/common/alert.service'
// Options
import StaticOptions from '@/common/options/StaticOptions';

// Toast
import ToastificationSuccess from '@core/components/toastification/ToastificationSuccess.vue'
import { required } from '@validations';
import { ValidationProvider, ValidationObserver } from 'vee-validate'

export default {
  components: {
    ValidationObserver,
    ValidationProvider,
  },
  props: {
    modalObject: {
      type: Object,
    },
    callback: {
      type: Function,
      default() {
        return {}
      },
    },
  },
  data() {
    return {
      required,
      resultStatus: this.modalObject.item.orderResultStatus,
      orderResultTraitStatusOptions: StaticOptions.orderResultTraitStatusOptions,
    }
  },
  methods: {
    saveOrderResult() {
      this.$refs.saveOrderResult.validate().then(success => {
        if (success) {
          this.modalObject.item.orderResultStatus = this.resultStatus;
          const query = {
            orderResult: this.modalObject.item,
          };

          ApiService.post('OrderResultTraitHealth/save', query, this)
          .then(response => {
            if (response.statusCode === 200) {
              this.$toast({
                component: ToastificationSuccess,
                position: 'top-right',
                props: { text: response.message },
              })
              this.modalObject.onSuccess();
              this.hiddenModal();
            } else {
             AlertService.error(this, response.message)
            }
          });
        }
      })
    },

    initialModal() {
      this.$refs['modal-save'].show();
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.saveOrderResult()
    },
    hiddenModal() {
      this.$refs['modal-save'].hide()
      this.modalObject.showModal = false;
    },
  },
  computed: {
    files() {
      return this.$store.getters['orderManagement/getOrderUploadedFiles']
    },
  },
  mounted() {
    this.initialModal();
  },
  created() {
  },
};
</script>
